import { readonly } from 'vue'

export const PERMISSION_NAMES = readonly({
    createCardComments: 'create_card_comments',
    createIncomingCarriers: 'create_incoming_carriers',
    createPayments: 'create_payments',
    createPhoneNumberPermissions: 'create_phone_number_permissions',
    createPhoneNumbers: 'create_phone_numbers',
    createRates: 'create_rates',
    createRoles: 'create_roles',
    createUsers: 'create_users',
    createVoicePlatformAccounts: 'create_voice_platform_accounts',
    seeExternalPhoneNumbers: 'see_external_phone_numbers',
    createExternalPhoneNumbers: 'create_external_phone_numbers',
    deleteCardComments: 'delete_card_comments',
    deleteIncomingCarriers: 'delete_incoming_carriers',
    deletePayments: 'delete_payments',
    deletePhoneNumberPermissions: 'delete_phone_number_permissions',
    deletePhoneNumbers: 'delete_phone_numbers',
    deleteRates: 'delete_rates',
    deleteRoles: 'delete_roles',
    deleteUsers: 'delete_users',
    deleteVoicePlatformAccounts: 'delete_voice_platform_accounts',
    deleteExternalPhoneNumbers: 'delete_external_phone_numbers',
    exportPayments: 'export_payments',
    seeAccountCallsLog: 'see_account_calls_log',
    seeAccountSmsLog: 'see_account_sms_log',
    seeAccounts: 'see_accounts',
    seeIncomingCarriers: 'see_incoming_carriers',
    seePayments: 'see_payments',
    seePhoneNumberPermissions: 'see_phone_number_permissions',
    seePhoneNumbers: 'see_phone_numbers',
    seeRates: 'see_rates',
    seeRegularUsers: 'see_regular_users',
    seeRoles: 'see_roles',
    seeUsers: 'see_users',
    listenToRecords: 'listen_to_records',
    seeVoicePlatformAccounts: 'see_voice_platform_accounts',
    seeNumberPool: 'see_number_pools',
    seeDashboard: 'see_dashboard',
    seeActivityLogs: 'see_activity_logs',
    updateAccounts: 'update_accounts',
    updateCardComments: 'update_card_comments',
    updateIncomingCarriers: 'update_incoming_carriers',
    updatePayments: 'update_payments',
    updatePhoneNumberPermissions: 'update_phone_number_permissions',
    updatePhoneNumbers: 'update_phone_numbers',
    updateRates: 'update_rates',
    updateRoles: 'update_roles',
    updateUsers: 'update_users',
    loginAsUser: 'login_as_user',
    notifyAccounts: 'notify_accounts',
    seeAffiliateUsers: 'see_affiliate_users',
    seeAffiliateReferrals: 'see_affiliate_referrals',
    seeAffiliateWithdrawals: 'see_affiliate_withdrawals',
    updateAffiliateReferrals: 'update_affiliate_referrals',
    updateAffiliateUsers: 'update_affiliate_users',
    updateAffiliateWithdrawals: 'update_affiliate_withdrawals',
    seeVoicePlatformCountryRates: 'see_voice_platform_country_rates',
    updateVoicePlatformCountryRates: 'update_voice_platform_country_rates',
    createVoicePlatformCountryRates: 'create_voice_platform_country_rates',
    seeVoicePlatformApplications: 'see_voice_platform_applications',
    updateVoicePlatformApplications: 'update_voice_platform_applications',

    seeMaintenanceMode: 'see_maintenance_mode',

    createOutboundTrunks: 'create_outbound_trunks',
    seeOutboundTrunks: 'see_outbound_trunks',
    updateOutboundTrunks: 'update_outbound_trunks',
    deleteOutboundTrunks: 'delete_outbound_trunks',
})
