import { defineStore } from 'pinia'

import { checkUserAccess, getLogout } from '@/api/auth-routes'
import { removeAccessToken } from '@/helpers/auth-helper'

import { useDefaultStore } from '@/store/defaultStore'

import { PERMISSION_NAMES } from '@/constants/permissions'
import { ROUTES } from '@/constants/routes'

export const useAuthorizationStore = defineStore('authorizationStore', {
    state: () => ({
        user: null,
        permissions: {},
    }),

    actions: {
        saveUserData({ user, permissions }) {
            this.user = user
            this.permissions = permissions
        },

        checkPermission(field) {
            return this.permissions ? !!this.permissions[field] : false
        },

        async getCheckUserAccess() {
            const { success, payload, message } = await checkUserAccess()

            if (success) {
                this.saveUserData({ user: payload.user, permissions: payload.permissions })
            } else {
                this.$reset()
                removeAccessToken()
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        getAvailableRouteByPermission() {
            switch (true) {
            case this.permissions[PERMISSION_NAMES.seeDashboard]:
                return ({ name: ROUTES.dashboard })
            case this.permissions[PERMISSION_NAMES.seeAccountCallsLog]:
                return ({ name: ROUTES.accountReport })
            case this.permissions[PERMISSION_NAMES.seeAccounts]:
                return ({ name: ROUTES.manageAccounts })
            case this.permissions[PERMISSION_NAMES.seeActivityLogs]:
                return ({ name: ROUTES.activityLogs })
            case this.permissions[PERMISSION_NAMES.seeAffiliateReferrals]:
                return ({ name: ROUTES.affiliateReferrals })
            case this.permissions[PERMISSION_NAMES.seeAffiliateUsers]:
                return ({ name: ROUTES.affiliates })
            case this.permissions[PERMISSION_NAMES.seeAffiliateWithdrawals]:
                return ({ name: ROUTES.withdrawalRequests })
            case this.permissions[PERMISSION_NAMES.seeIncomingCarriers]:
                return ({ name: ROUTES.incomingCarrier })
            case this.permissions[PERMISSION_NAMES.seeNumberPool]:
                return ({ name: ROUTES.numberPool })
            case this.permissions[PERMISSION_NAMES.seeOutboundTrunks]:
                return ({ name: ROUTES.outboundTrunk })
            case this.permissions[PERMISSION_NAMES.seePayments]:
                return ({ name: ROUTES.paymentsHistory })
            case this.permissions[PERMISSION_NAMES.seePhoneNumberPermissions]:
                return ({ name: ROUTES.numberPermissions })
            case this.permissions[PERMISSION_NAMES.seePhoneNumbers]:
                return ({ name: ROUTES.numbers })
            case this.permissions[PERMISSION_NAMES.seeRates]:
                return ({ name: ROUTES.accountRates })
            case this.permissions[PERMISSION_NAMES.seeRegularUsers]:
                return ({ name: ROUTES.usersList })
            case this.permissions[PERMISSION_NAMES.seeRoles]:
                return ({ name: ROUTES.roles })
            case this.permissions[PERMISSION_NAMES.seeUsers]:
                return ({ name: ROUTES.adminUsers })
            case this.permissions[PERMISSION_NAMES.seeExternalPhoneNumbers]:
                return ({ name: ROUTES.byoc })
            case this.permissions[PERMISSION_NAMES.seeVoicePlatformApplications]:
                return ({ name: ROUTES.manageVoicePlatforms })
            case this.permissions[PERMISSION_NAMES.seeVoicePlatformCountryRates]:
                return ({ name: ROUTES.countryRates })
            default:
                return ({ name: ROUTES.notFound })
            }
        },

        async logout() {
            const defaultStore = useDefaultStore()

            const { success, message } = await getLogout()

            if (success) {
                defaultStore.setSuccessMessage({ message })
                this.$reset()
                removeAccessToken()
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        filterMenuByPermission(menuItems) {
            return menuItems.filter((item) => {
                const hasPermission = item.permissions
                    ? item.permissions.some((permission) => this.checkPermission(permission))
                    : false

                if (item.subMenuAll) {
                    item.subMenu = this.filterMenuByPermission(item.subMenuAll)
                }

                return hasPermission || (item.subMenu && item.subMenu.length > 0)
            })
        },
    },
})
