import { createRouter, createWebHistory } from 'vue-router'
import { getAccessToken } from '@/helpers/auth-helper'

import nextFactory from '@/router/middleware/nextFactory'

import { BASE_ROUTES } from '@/router/config/baseRoutes'
import { useAppStore } from '@/store/appStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: BASE_ROUTES,
})

router.beforeEach(async (to, from, next) => {
    const appStore = useAppStore()
    appStore.setRouteLoading(true)

    const authorizationStore = useAuthorizationStore()

    if (!!getAccessToken() && !authorizationStore.user) {
        authorizationStore.$reset()
        await authorizationStore.getCheckUserAccess()
    }

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
        const context = {
            to, from, next, store: authorizationStore, router,
        }
        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({ ...context, next: nextMiddleware })
    }

    return next()
})

router.onError((error, to) => {
    window.open(to.path, '_self')
})
router.beforeResolve(() => {
    const appStore = useAppStore()
    appStore.setRouteLoading(false)
})

export default router
